import React, { useContext } from "react";

//Style
import "./ContactNavbar.css";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const ContactNavbar = ({ social }) => {
  const { nbContact } = useContext(NavbarContext);

  return (
    <section ref={nbContact} className="contact-navbar-section">
      <div className="contact-navbar-subcontainer">
        <div className="contact-container">
          <img
            className="contact-icon"
            src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/home/assets/client-portal-icon.webp"
            alt=""
            loading="lazy"
          />
          <a
            href="https://portal.crediseguro.co/login"
            target="_blank"
            title="Acceso Funcionarios"
            className="contact-txt btn-cn"
          >
            Portal Intermediarios
          </a>
        </div>
        <div className="contact-container">
          <img
            className="contact-icon"
            src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/person-icon.svg"
            alt=""
            loading="lazy"
          />
          <a
            href="https://crediseguro.my.salesforce.com"
            target="_blank"
            title="Acceso Funcionarios"
            className="contact-txt btn-cn"
          >
            Acceso Funcionarios
          </a>
        </div>
        <div className="contact-container">
          <img
            className="contact-icon"
            src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/phone-icon.svg"
            alt=""
            loading="lazy"
          />
          <div className="contact-txt">Serv. al Cliente: (300) 292-4458</div>
        </div>
        <div className="contact-container">
          <img
            className="contact-icon"
            src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/email-icon.svg"
            alt=""
            loading="lazy"
          />
          <div className="contact-txt">servicioalcliente@crediseguro.co</div>
        </div>
        <div className="contacts-container-mobile">
          <div className="contact-subcontainer-mobile">
            <img
              className="contact-icon"
              src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/phone-icon.svg"
              alt=""
              loading="lazy"
            />
            <div className="contact-txt">(301) 599-3123</div>
          </div>
          <div className="contact-subcontainer-mobile">
            <img
              className="contact-icon"
              src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/email-icon.svg"
              alt=""
              loading="lazy"
            />
            <div className="contact-txt">servicioalcliente@crediseguro.co</div>
          </div>
        </div>
        <div className="social-media-container">
          {social.map((sm, i) => {
            return (
              <a key={i} href={sm.href} title={sm.title} target="_blank">
                <img
                  className="social-media-icon"
                  onMouseOver={(e) => (e.target.src = sm.srcHover)}
                  onMouseLeave={(e) => (e.target.src = sm.src)}
                  src={sm.src}
                  alt={sm.title}
                />
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ContactNavbar;
