import React, { useState } from "react";

//Style
import "./BannerSection.css";

const BannerSection = () => {
  const [magicCounter, setMagicCounter] = useState(0);

  const [showLittleFarmer, setshowLittleFarmer] = useState(false);

  const handleMagicCounter = () => {
    setMagicCounter(magicCounter + 1);
    if (magicCounter == 10) {
      setshowLittleFarmer(true);
      alert("Se supone que tienes que darle clic al botón!! ( ͡° ͜ʖ ͡°)");
    } else {
      setshowLittleFarmer(false);
    }
  };

  return (
    <section className="banner-section">
      <div className="banner-subcontainer">
        <img
          src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/banner/banner-img.webp"
          srcset= "https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/banner/alt-img/banner-img.webp 553w, https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/banner/banner-img.webp"
          sizes= "(max-width: 1023px) 553px, (min-width: 1024px)"
          alt=""
          className="main-img"
          loading="eager"
        />
        <div className="banner-info-container">
          <h1 className="banner-title">
            Realiza el <strong>pago</strong> de la{" "}
            <strong>financiación </strong>
            de la póliza de tu <strong>seguro</strong>
          </h1>
          <img
            src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/banner/banner-deco-img.svg"
            alt=""
            className="second-img"
            loading="lazy"
          />
          <div className="banner-btn-info-container">
            <a
              href="https://pagos.crediseguro.co/#/"
              className="btn-banner-info"
              target="_blank"
              title="Pago en Línea"
            >
              <strong>Pago en </strong>&nbsp;Línea
            </a>
            <img
              onClick={handleMagicCounter}
              src={
                showLittleFarmer
                  ? "https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/super-important-img.webp"
                  : "https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/assets/hand-img.svg"
              }
              alt=""
              className="btn-icon"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
