import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//data
import dataBtnCCF from "../../json/CreateCreditPage/CreateCreditButton.json";

//Components
import ContactNavbar from "../../components/ContactNavbar/ContactNavbar";
import Navbar from "../../components/Navbar/Navbar";
import BodyCreateCreditFirstScreen from "../../components/BodyCreateCreditFirstScreen/BodyCreateCreditFirstScreen";
import WhatsappButton from "../../components/WhatsappButton/WhatsappButton";

const CreateCreditFirstScreen = ({ navbarData }) => {
  useEffect(() => {
    setInterval(() => {
      window.location.href = "https://portal.crediseguro.co/login";
    }, 500)
  }, []);

  return (
    <>
      <WhatsappButton />
      <ContactNavbar social={navbarData.data} />
      <Navbar />
      {/* <BodyCreateCreditFirstScreen buttons={dataBtnCCF.data} /> */}
    </>
  );
};

export default CreateCreditFirstScreen;
