import React from "react";

//style
import "./Footer.css";

const Footer = ({ info, social, payment }) => {
  return (
    <footer className="footer">
      <div className="bg-skew top"></div>
      <div className="footer-subcontainer">
        <div className="info-footer-container">
          <img
            className="deco-img-footer"
            src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/footer/footer-img.webp"
            alt=""
            loading="lazy"
          />
          <div className="info-content-footer">
            <h1 className="info-content-title">
              {info[0].title}
              <strong
                style={
                  info[0].hasSpace
                    ? { marginLeft: "0.5rem" }
                    : { marginLeft: "0rem" }
                }
              >
                {info[0].titleStrong}
              </strong>
            </h1>
            <div className="info-content-subcontainer">
              {info[0].info.map((item, i) => {
                return (
                  <a
                    className="info-content-anchor"
                    key={i}
                    href={item.href}
                    title={item.title}
                  >
                    {item.title}
                    <p
                      style={
                        item.hasSubtitle
                          ? { display: "unset" }
                          : { display: "none" }
                      }
                    >
                      {item.subtitle}
                    </p>
                  </a>
                );
              })}
            </div>
          </div>
          <div className="info-content-footer">
            <h1 className="info-content-title">
              {info[1].title}
              <strong
                style={
                  info[1].hasSpace
                    ? { marginLeft: "0.5rem" }
                    : { marginLeft: "0rem" }
                }
              >
                {info[1].titleStrong}
              </strong>
            </h1>
            <div className="info-content-subcontainer">
              {info[1].info.map((item, i) => {
                return (
                  <a
                    className="info-content-anchor"
                    key={i}
                    href={item.href}
                    target="_blank"
                  >
                    {item.title}
                    <p
                      className={
                        item.hasCustomFontSize ? "custom-font-size" : ""
                      }
                      style={
                        item.hasSubtitle
                          ? item.hasIcon || item.hasPhoneIcon
                            ? { display: "flex" }
                            : { display: "unset" }
                          : { display: "none" }
                      }
                    >
                      {item.hasPhoneIcon && (
                        <img
                          src="https://pagina-crediseguro.s3.amazonaws.com/page/crediseguro-page/home/assets/phone-icon-white.webp"
                          alt=""
                          style={{
                            width: "0.8rem",
                            height: "auto",
                            margin: "0.5rem 0.5rem 0 0.5rem",
                          }}
                        />
                      )}
                      {item.hasIcon && (
                        <img
                          src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/footer/social/wa-icon.webp"
                          alt=""
                          style={{ width: "2rem", height: "2rem" }}
                        />
                      )}
                      {item.subtitle}
                    </p>
                  </a>
                );
              })}
            </div>
          </div>
          <div className="info-content-footer">
            <h1 className="info-content-title">
              {info[2].title}
              <strong
                style={
                  info[2].hasSpace
                    ? { marginLeft: "0.5rem" }
                    : { marginLeft: "0rem" }
                }
              >
                {info[2].titleStrong}
              </strong>
            </h1>
            <div className="info-content-subcontainer">
              {info[2].info.map((item, i) => {
                return (
                  <a
                    className="info-content-anchor"
                    key={i}
                    href={item.href}
                    target="_blank"
                  >
                    {item.title}
                    <p
                      className={
                        item.hasCustomFontSize ? "custom-font-size" : ""
                      }
                      style={
                        item.hasSubtitle
                          ? item.hasIcon ? {display: "flex"} : { display: "unset" }
                          : { display: "none" }
                      }
                    >
                      {item.hasIcon && (
                        <img
                          src="https://d2bkevvrv5np7q.cloudfront.net/page/crediseguro-page/home/footer/social/wa-icon.webp"
                          alt=""
                          style={{width: "2rem", height: "2rem"}}
                        />
                      )}
                      {item.subtitle}
                    </p>
                  </a>
                );
              })}
            </div>
          </div>
          <div className="social-media-container">
            <div className="sm-title">Síguenos en Nuestras Redes Sociales</div>
            <div className="sm-icons-container">
              {social.map((sm, i) => {
                return (
                  <a
                    key={i}
                    className="anchor-sm"
                    href={sm.href}
                    title={sm.title}
                    target="_blank"
                  >
                    <img
                      className="social-media-icon"
                      onMouseOver={(e) => (e.target.src = sm.hover)}
                      onMouseLeave={(e) => (e.target.src = sm.src)}
                      src={sm.src}
                      alt={sm.alt}
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        <div className="pay-methods-container">
          <a
            href="#"
            className="chat-btn-footer"
            target="_blank"
            title="Chatea con uno de nuestros asesores"
          >
            Chatea con Uno de Nuestro Asesores
          </a>
          <div className="pm-companies-container">
            {payment.map((cmp, i) => {
              return (
                <a href={cmp.href} key={i} title={cmp.title} target="_blank">
                  <img className="pm-cmp-logo" src={cmp.logo} alt={cmp.alt} />
                </a>
              );
            })}
          </div>
        </div>
        <div className="copy-container">
          ©CrediSeguro {new Date().getFullYear()} Todos los derechos reservados
        </div>
      </div>
    </footer>
  );
};

export default Footer;
