import React, { useState } from "react";

//Style
import "./WhatsappButton.css";

const WhatsappButton = () => {
  const [hoverWa, setHoverWa] = useState(false);

  return (
    <div className="wa-btn-container">
      <a
        id="Formulario_Whatsapp"
        onMouseEnter={() => setHoverWa(true)}
        onMouseLeave={() => setHoverWa(false)}
        className="wa-btn-icon-container"
        title="Whatsapp"
        href="https://api.whatsapp.com/send?phone=573002924458"
        target="_blank"
      >
        <img
          className="wa-icon"
          src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/assets/whatsapp-icon.svg"
          alt=""
          loading="lazy"
        />
      </a>
      <div
        className={hoverWa ? "wa-hover-element show" : "wa-hover-element hide"}
      >
        <h4>Contáctanos</h4>
      </div>
    </div>
  );
};

export default WhatsappButton;
